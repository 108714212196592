
import { defineComponent } from 'vue';
import { defaultTeamLogoURL, randomTeamName } from '@/utils.names';
import { divisionLongDisplayName } from '@/utils';
import { MUTATION } from '@/store/mutations';
import { store } from '@/store/store';

export default defineComponent({
    name: 'TeamAdd',
    components: { },
    props: {
        division: {
            type: String,
            required: false,
            default: ''
        },
        pool: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        formattedDivision(): string {
            return divisionLongDisplayName(this.division, this.pool)
        }
    },
    methods: {
        addTeam(){
            store.commit(MUTATION.SHOW_ADD_TEAM_DIALOG, {
                show: true,
                team: {
                    _id: '',
                    division: this.division ?? '',
                    pool: this.pool ?? '',
                    join: new Date().getFullYear(),
                    name: randomTeamName(),
                    logo: defaultTeamLogoURL(),
                    picture: undefined,
                    color: '#2c3e50'
                }
            });
        },
    }
});
