
import { defineComponent } from 'vue';
import Popup from '@/components/lib/Popup.vue';
import { POPUP_TYPES } from '@/constants';
import CSS from 'csstype';

export function getFontStyle(size: number) {
    const family = 'Arial';
    return size + 'px ' + family;
}

export default defineComponent({
    name: 'ConfirmAction',
    components: {
        Popup
    },
    props: {
        invert: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: true,
        },
        behavior: {
            type: String,
            required: false,
            default: 'confirm' // confirm || reject
        },
        icon: {
            type: String,
            required: false,
        },
        msg: {
            type: String,
            required: false,
            default: ''
        },
        confirmMsg: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            top: 0,
            left: 0,
            show: false,
            showTimer: 0,
            dropdown: POPUP_TYPES.DROP_DOWN
        }
    },
    computed: {
        popupFilterPosition(): CSS.Properties {
            return {top: `${this.top}px`, left: `${this.left}px`} as CSS.Properties;
        },
        confirmStr(): string {
            return (
                this.type==='custom' && this.confirmMsg?
                    this.confirmMsg:
                    'Appuyer une seconde fois pour confirmer'
            )
        }
    },
    methods: {
        toggleConfirm() {
            if(this.show) {
                clearTimeout(this.showTimer);
                this.$emit('confirm');
                this.show = false;
            }
            else {
                const trigger = (this.$refs.trigger as HTMLElement).getBoundingClientRect();
                const canvas = document.createElement('canvas');
                canvas.style.position = 'absolute';
                canvas.style.top = "-9999px";
                canvas.style.left = "-9999px";
                document.body.appendChild(canvas);
                const ctx =  canvas.getContext('2d');
                if(!ctx) {
                    console.error('Cannot create canvas');
                    return;
                }
                ctx.font = getFontStyle(14);
                ctx.textAlign = 'left';
                ctx.textBaseline = 'middle';
                ctx.fillText(this.confirmStr, 0, 0);
                const len = ctx.measureText(this.confirmStr).width ?? 0;
                document.body.removeChild(canvas);
                this.top = trigger.y - 2;
                this.left = this.invert? trigger.x + trigger.width + 2: trigger.x - len - 60;
                this.show = true;
                this.showTimer = setTimeout(() => {
                    this.show = false;
                }, 3000);
            }
        }
    }
})
