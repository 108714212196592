
import { defineComponent } from 'vue';
import { TeamStats } from '@/services/stats';
import TeamLabel from '@/components/TeamLabel.vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { TeamInterface } from '@/services/teams';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { STATE } from '@/constants';
import { MUTATION } from '@/store/mutations';

export default defineComponent({
    name: 'TeamCard',
    components: { TeamLabel, ConfirmAction },
    data () {
        return {
            showTeamLogo: true
        }
    },
    props: {
        team: {
            type: Object as () => TeamInterface,
            required: true
        },
        stats: {
            type: Object as () => TeamStats,
            required: false,
            default: {
                team: '',
                games: 0,
                win: 0,
                nul: 0,
                lost: 0,
                pa: 0,
                pf: 0,
                diff: 0,
                points: 0,
                awaitingApproval: false
            } as TeamStats
        },
        rank: {
            type: Number,
            required: false,
            default: -1
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        teams(): TeamInterface[] {
            return store.state.teams;
        },
        teamName(): string {
            if(!this.team) return '';
            else return this.team.name;
        },
        teamJoin(): number {
            return this.team.join;
        },
        teamColor(): string {
            return this.team.color;
        },
        hasTeamPicture(): boolean {
            return this.team.picture !== undefined
        },
        teamPictureUrl(): string {
            return this.team.picture ?? '';
        },
        hasRank(): boolean {
            return this.rank > -1
        },
        hasStats(): boolean {
            return this.stats !== undefined
        },
        isBasicUser(): boolean {
            return store.getters.isBasic;
        },
        teamLink(): string {
            return `/teams/${this.teamName}`;
        },
        isTeamCaptain(): boolean {
            if(!this.team) return false;
            return store.getters.isTeamCaptain(this.teamName);
        },
        canEdit(): boolean {
            return store.getters.isAdmin;
        },
        favoriteTeam(): boolean {
            const teamId = this.team._id;
            return store.getters.teamsIFavorite.some(t => teamId === t._id);
        },
        isRemoving(): boolean {
            return store.state.removeTeamRequestStatus[this.team._id]?.status === STATE.LOADING
        },
        joinYearVisible(): boolean {
            return store.getters.joinYearVisible;
        }
    },
    methods: {
        gotoTeam(){
            if(this.editMode) return;
            this.$router.push({name: 'SpecificTeam', params: {teamName: `${this.team.name}`}});
        },
        toggleFavoriteTeam(): void {
            if(this.editMode) return;
            if(this.favoriteTeam) store.dispatch(ACTION.REMOVE_FROM_FAVORITE_TEAM, this.team)
            else                  store.dispatch(ACTION.ADD_TO_FAVORITE_TEAM, this.team)
        },
        removeTeam() {
            store.dispatch(ACTION.REMOVE_TEAM, this.team._id);
        },
        editTeam() {
            store.commit(MUTATION.SHOW_ADD_TEAM_DIALOG, {
                show: true, team: this.team
            });
        }
    }
});
