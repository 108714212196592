
import { defineComponent } from 'vue';
import TeamCard from '@/components/TeamCard.vue';
import TeamAdd from '@/components/TeamAdd.vue';
import { TeamInterface, TFilteredTeamsByDivision } from '@/services/teams';
import { store } from '@/store/store';
import { TeamStats } from '@/services/stats';
import { decodeTeamDivision, divisionLongDisplayName } from '@/utils';
import { ACTION } from '@/store/actions';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'Teams',
    components: { TeamCard, TeamAdd },
    computed: {
        teams(): TeamInterface[] {
            return store.state.teams;
        },
        sortedCategories(): string[] {
            return store.getters.sortedSeasonCategories;
        },
        teamsInDivision(): TFilteredTeamsByDivision {
            return store.getters.teamsPerDivision;
        },
        canAddTeam(): boolean {
            return store.getters.isAdmin;
        }
    },
    async mounted() {
        if(store.state.seasonRankingRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
        }
    },
    methods: {
        teamStats(team: TeamInterface): TeamStats | undefined {
            return store.getters.teamStats(team.name);
        },
        teamRank(team: TeamInterface): number {
           return store.getters.teamRank(team.name);
        },
        divisionName(div: string): string {
            const {division, pool} = decodeTeamDivision(div);
            return divisionLongDisplayName(division, pool);
        },
        getDivision(div: string) {
            const {division} = decodeTeamDivision(div);
            return division;
        },
        getPool(div: string) {
            const {pool} = decodeTeamDivision(div);
            return pool;
        }
    }
});
